<template>
  <div class="view pa24" style="position: relative">
    <div class="d-flex" style="justify-content: space-between">
      <div>
        <el-input clearable class="w221 mr10" v-model="orderFrom" placeholder="请输入订单来源"/>
        <el-input clearable class="w221 mr10" v-model="ordersNo" placeholder="请输入订单编号"/>
        <el-input clearable class="w221 mr10" v-model="goodsName" placeholder="请输入商品名称"/>
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
<!--      <el-button class="ml10" type="primary" @click="jiesuanAll"-->
<!--      >批量结算-->
<!--      </el-button-->
<!--      >-->
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
        :currentPage="currentPage"
        :total="total"
        style="width: 100%;position: relative"
    >
      <template v-slot:table>
<!--        <el-table-column type="selection"/>-->
        <el-table-column prop="orderFrom" align="center" label="订单来源"/>
        <el-table-column prop="ordersNo" align="center" label="订单编号" />
        <el-table-column prop="orderPrice" align="center" label="订单金额"/>
        <el-table-column prop="goodsName" align="center" label="商品名称"/>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.goodsFrom==1" @click="toJieSuan(scope.row)">线下结算</el-button>
            <el-button class=""  v-if="scope.row.goodsFrom==1" type="text" @click="toDaKuan(scope.row)">线上打款</el-button>
            <el-button class=""  v-if="scope.row.goodsFrom!=1" type="text" disabled >待组织结算</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="打款凭证"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
    >
      <postArticles @submitForm="submitForm" :orderPrice="orderPrice"  ref="postArticles"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import postArticles from "./postArticles";
import {
  selectOrdersPageList,addPaymentVoucher
} from "@/api/settlement";

let base64 = require('js-base64').Base64
export default {
  name: "forThe",
  data() {
    return {
      orderFrom: '',
      ordersNo: '',
      goodsName: '',
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      centerDialogVisible: false,
      tableData: [],
      multipleSelection: [],
      orderPrice:'',
      row:{}
    };
  },
  created() {
    this.queryPage()
  },
  components: {
    commonTable,
    postArticles
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //二维码显示
    async changewxImg(row) {
      this.memberId = row.memberId
      let ajax = {
        companyId: localStorage.getItem("companyId"),
        memberId: this.memberId
      }
      try {
        const result = await selectWxCodeByMemberId(ajax);
        this.twoCode = result.data.twoCode
      } catch (error) {

      }
    },
    async close(item) {
      this.twoCode = ''
    },
    /**@method 获取*/
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ifSettlement:1,
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectOrdersPageList(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        for (let i in list) {
          list[i].orderPrice = (list[i].orderPrice/100).toFixed(2)
          if (list[i].goodsFrom == 0) {
            list[i].orderFrom = '企业自营'
          }else if (list[i].goodsFrom == 1) {
            list[i].orderFrom = '组织下放'
          }else if (list[i].goodsFrom == 2) {
            list[i].orderFrom = '组织自营'
          }else if (list[i].goodsFrom == 3) {
            list[i].orderFrom = '企业上传'
          }else if (list[i].goodsFrom == 4) {
            list[i].orderFrom = '组织-媒体挂载商品'
          }else if (list[i].goodsFrom == 5) {
            list[i].orderFrom = '企业-媒体挂载商品'
          }else if (list[i].goodsFrom == 6) {
            list[i].orderFrom = '组织-获客文章挂载'
          }else if (list[i].goodsFrom == 7) {
            list[i].orderFrom = '企业-获客文章挂载'
          }else if (list[i].goodsFrom == 8) {
            list[i].orderFrom = '高级版'
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        orderFrom: this.orderFrom,
        ordersNo: this.ordersNo,
        goodsName: this.goodsName,
      };
      this.queryPage(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        orderFrom: this.orderFrom,
        ordersNo: this.ordersNo,
        goodsName: this.goodsName,
      };
      this.queryPage(data);
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        orderFrom: this.orderFrom,
        ordersNo: this.ordersNo,
        goodsName: this.goodsName,
      };
      this.queryPage(data);
    },
    determine(data) {
      this.$refs.postArticles.submitForm(data);
    },
    /**
     * @method 发布文章提交
     * @param {Object} val - 表单值
     */
    async submitForm(val) {
      let data = {
        associationId: localStorage.getItem("associationId"),
        paymentType: val.way,
        payee: val.name,
        payAmount: parseInt(this.orderPrice*100),
        paymentAmount: parseInt(val.amountMoney*100),
        paymentVoucher:val.coverImg,
        paymentTime:this.renderTime(val.dkDate),
        remark:val.desc,
        companyName:this.row.companyName,
        companyId:this.row.companyId,
      };
      if (Number(this.orderPrice) == Number(val.amountMoney)){
        data.remainingAmount = 0
      }else {
        data.remainingAmount = 1
      }
      this.row.orderPrice = parseInt(this.row.orderPrice*100)
      data.ordersList = JSON.stringify([this.row])
      try {
        await addPaymentVoucher(data, {showLoading: true});
        this.centerDialogVisible = false;
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    toJieSuan(row){
      this.row = row
      this.orderPrice = row.orderPrice
      this.centerDialogVisible = true
    },
    toDaKuan(){
      this.$message.error('暂不支持线上打款')
    },
    jiesuanAll(){
      this.$router.push({
        path: "../settlementAll",
        query: {
          // companyId: row.companyId,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.code_img {
  position: absolute;
  top: 30%;
  z-index: 10000;
  left: 35%;
  background: #FFFFFF;
}

/deep/ .el-table__body {
  position: relative;
}
</style>

