<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
      <el-form-item prop="way" label="打款方式" style="width: 100%">
        <el-col :xl="10">
          <el-select clearable v-model="ruleForm.way" placeholder="请选择打款方式" style="width: 100%">
            <template v-for="(item,index) in wayList">
              <el-option
                  :label="item.name"
                  :value="item.index"
                  :key="'info'+ index"
              />
            </template>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="收款人" prop="name">
        <el-col :xl="10">
          <el-input
              placeholder="请输入收款人"
              v-model="ruleForm.name"
              clearable
              maxlength="50"
              show-word-limit
          />
        </el-col>
      </el-form-item>
      <el-form-item label="应付金额" prop="amountPayable">
        <el-col :xl="10">
          <el-input
              placeholder="请输入应付金额"
              v-model="orderPrice"
              clearable
              maxlength="20"
              show-word-limit
              disabled
          />
        </el-col>
      </el-form-item>
      <el-form-item label="打款金额" prop="amountMoney">
        <el-col :xl="10">
          <el-input
              placeholder="请输入打款金额"
              v-model="ruleForm.amountMoney"
              :maxlength="inputMaxL"
              min="0"
              @input="inputMaxL = /^\d+\.?\d{0,1}$/.test(ruleForm.amountMoney) ? null : ruleForm.amountMoney.length - 1"
              clearable
              @blur="toMoney"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="剩余款项" prop="remaining" v-if="isShow">
        <el-col :xl="10">
          <el-radio v-model="ruleForm.remaining" label="0">抹零</el-radio>
        </el-col>
      </el-form-item>
      <el-form-item prop="coverImg" label="打款凭证">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过2M
        </p>
        <div>
          <el-upload :action="uploadUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :before-upload="beforeAvatarUploadImg"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item label="打款时间" prop="dkDate">
        <el-col :xl="10">
          <el-date-picker
              style="width: 100%"
              v-model="ruleForm.dkDate"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item prop="desc" label="备注">
        <el-col :xl="10">
          <el-input
              placeholder="请输入备注"
              v-model="ruleForm.desc"
              type="textarea"
          />
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {selectMemberFeeList} from '@/api/organization'
import {selectMemberPageList,} from "@/api/members";

export default {
  props:['orderPrice'],
  data() {
    return {
      inputMaxL:10,
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      fileList:[],
      wayList: [{index: 0, name: '微信'}, {index: 1, name: '支付宝'}, {index: 2, name: '银行卡'}],
      ruleForm: {
        way: "",
        name: "",
        amountPayable: "",
        amountMoney: "",
        remaining: "",
        desc: "",
        dkDate:"",
      },
      rules: {
        name: [{required: true, message: "请输入姓名", trigger: "blur"}],
        way: [{required: true, message: "请选择打款方式", trigger: "blur"}],
        amountMoney: [{required: true, message: "请输入打款金额", trigger: "change"}],
      },
      isShow:false
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  mounted() {

  },
  methods: {
    toMoney(){
      if (Number(this.ruleForm.amountMoney) != Number(this.orderPrice)) {
        this.isShow = true
      }else {
        this.isShow = false
      }
    },
    submitForm(payload) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          this.$emit("submitForm", data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.coverImg = ''
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2M!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>

